import React from 'react'
import { Layout, Breadcrumbs } from '@common'
import { AttentionSection, CategoriesSection, BlogSection, NewsletterSection } from '@sections'

import './styles/blog.scss'

const Blog = ({ pageContext }) => {
	const { posts, page } = pageContext
	return (
		<Layout seo={pageContext.page.seo}
		siteMetaData={pageContext.page.siteMetaData}>
		<AttentionSection title={page.title}/>
			<div className='blog'>
					<div className='container'>
				<Breadcrumbs
					elements={[
						{ label: page.title, url: '/blog/' },
					]}
				/>
				<BlogSection title={''} posts={posts} slider={false} button={false}/>
				
				{/*
				<CallTherapistSection />
				<NewsletterSection />
				*/}

				</div>
			</div>
		</Layout>
	)
}

export default Blog
